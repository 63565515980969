import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import GitHubButton from "react-github-btn";
import * as Icon from "react-feather";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing";
import SEO from "../components/SEO";
import config from "../../blogposts/data/SiteConfig";
import quotes from "../../blogposts/data/quotes";
import pratap from "../../blogposts/images/profile.jpg";
import UserInfo from "../components/UserInfo";
import ViewCounter from "../components/ViewCounter";

const Index = ({ data }) => {
  const [isShown, setIsShown] = useState(false);
  const isDisplayedToday = () => {
    const lastClosed =
      typeof window !== "undefined" &&
      window.localStorage.getItem("LAST_CLOSED");
    if (lastClosed) {
      const todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);
      return lastClosed < todayDate;
    }
    return true;
  };

  useEffect(() => {
    const isFirst = isDisplayedToday();
    setIsShown(isFirst);
  }, []);

  const setShowReminder = () => {
    window.localStorage.setItem("LAST_CLOSED", Date.now());
    setIsShown(false);
  };

  const latestPostEdges = data.latest.edges;
  const popularPostEdges = data.popular.edges;
  const dataStructurePostEdges = data.dataStructure.edges;
  const podcastPostEdges = data.podcast.edges;
  const latestPost = latestPostEdges[0];

  return (
    <Layout>
      <Helmet title={`${config.siteTitle} – Full Stack Software Developer`} />
      <SEO />

      {latestPost && (
        <div
          className="reminder fadeInUp"
          style={{
            animationDelay: "1s",
            display: isShown ? "" : "none",
          }}
        >
          <Icon.XCircle onClick={setShowReminder} />
          <p>
            <Link to={latestPost.node.fields.slug}>
              Checkout the latest article:{" "}
              <strong>{latestPost.node.frontmatter.title}</strong>
            </Link>
          </p>
        </div>
      )}
      <span style={{ display: "none" }}>
        <ViewCounter id="index" />
      </span>
      <div className="container">
        <div className="lead">
          <div className="elevator">
            <h1>{`Hey, I'm Pratap 👋`} </h1>
            <p>
              {`I am a Software Engineer with 6+ years of experience in building and deploying web applications using varying tech stacks. My expertise lies in the frontend, backend and mobile apps.`}
              <br /><br />I <Link to="/blog">write</Link> about System Design, modern JavaScript,
              Python, Node.js, React, React-Native, mobile, desktop and web app
              development. Checkout my road to glory from the{" "}
              <Link to="/timeline">timeline</Link>.
              <br />
              <br />
              Try the <Link to="/sip-calculator">SIP calculator</Link> to see
              how you can benefit from the power of systematic investing.
            </p>
            <div className="social-buttons">
              <GitHubButton
                href="https://github.com/pratap22"
                data-size="large"
                data-show-count="true"
              >
                pratap22
              </GitHubButton>
            </div>
            <div className="social-buttons">
              <a
                href="https://stackoverflow.com/users/10398005/pratap-sharma"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://stackoverflow.com/users/flair/10398005.png"
                  width="208"
                  height="58"
                  alt="profile for Pratap Sharma at Stack Overflow, Q&amp;A for professional and enthusiast programmers"
                  title="profile for Pratap Sharma at Stack Overflow, Q&amp;A for professional and enthusiast programmers"
                />
              </a>
            </div>
          </div>
          <div className="newsletter-section">
            <img src={pratap} className="newsletter-avatar" alt="Pratap" />
            <div>
              <h3>Email Newsletter</h3>
              <p>
                I write tutorials. Get an update when something new comes out by
                signing up below!
              </p>
              <a
                className="button"
                target="_blank"
                rel="noopener noreferrer"
                href="https://pratap.substack.com"
              >
                Subscribe
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="container front-page">
        <section className="section">
          <h2>
            Latest Articles
            <Link to="/blog" className="view-all">
              View all
            </Link>
          </h2>
          <PostListing simple postEdges={latestPostEdges} />
        </section>
        {podcastPostEdges && podcastPostEdges.length > 0 && (
          <section className="section">
            <h2>
              Podcasts
              {podcastPostEdges.length > 5 && (
                <Link to="/categories/podcast" className="view-all">
                  View all
                </Link>
              )}
            </h2>
            <PostListing simple postEdges={podcastPostEdges} />
          </section>
        )}

        {popularPostEdges && popularPostEdges.length > 0 && (
          <section className="section">
            <h2>
              Most Popular
              <Link to="/categories/popular" className="view-all">
                View all
              </Link>
            </h2>
            <PostListing simple postEdges={popularPostEdges} />
          </section>
        )}

        {dataStructurePostEdges && dataStructurePostEdges.length > 0 && (
          <section className="section">
            <h2>
              Data Structure Solutions
              <Link to="/categories/data-structure" className="view-all">
                View all
              </Link>
            </h2>
            <PostListing simple postEdges={dataStructurePostEdges} />
          </section>
        )}
      </div>
      <UserInfo config={config} />
      <div className="gradient-section">
        <div className="container">
          <h2>What people say about me...</h2>
          <h5>The quotes are collated from my LinkedIn recommendations.</h5>
        </div>
        <div className="quotations">
          {quotes
            .filter((quote, index) => index < 4)
            .map((quote) => (
              <blockquote className="quotation" key={quote.name}>
                <p>{quote.quote}</p>
                <cite>
                  —{" "}
                  <a
                    href={quote.profileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {quote.name}
                  </a>
                </cite>
              </blockquote>
            ))}
        </div>
        {quotes.length > 4 && (
          <div
            className="container"
            style={{ textAlign: "end", marginTop: 40 }}
          >
            <Link to="/quotes/" className="button">
              View More Quotes
            </Link>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Index;

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 5
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
    popular: allMarkdownRemark(
      limit: 5
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Popular" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
    dataStructure: allMarkdownRemark(
      limit: 5
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Data-Structure" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
    podcast: allMarkdownRemark(
      limit: 5
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Podcast" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                fixed(width: 150, height: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date
            template
          }
        }
      }
    }
  }
`;
