export default [
  {
    name: "Sudan S S",
    quote: `Pratap has good work ethics. Always willing to push his limits in solving the problem. Techie+Writer combination- he has winning mindset.`,
    profileUrl: "https://www.linkedin.com/in/suddanss/",
  },
  {
    name: "Rakesh Kumar Sah",
    quote: `Pratap has a humble background with solid in-depth knowledge in building scalable and robust web applications. It was a great learning curve for me to learn react in depth from him. In addition to his decent problem solving approach he is got great talents in building a full stack web applications with modern design principles.`,
    profileUrl: "https://www.linkedin.com/in/rakesh-kumar-sah-a43187118/",
  },
  {
    name: "Allan Joseph",
    quote: `Pratap is a great guy with a professional and a go-getter attitude towards his commitments. I personally worked with him on projects and he has given a good delivery on them. Plus he is a great helping hand to people.`,
    profileUrl: "https://www.linkedin.com/in/allan-joseph-b9b4a7185/",
  },
  {
    name: "Mala Kisku",
    quote: `I have known Pratap since 2k14, we completed our btech together and still are constantly in touch. I can say that he is extremely talented and is expertise in whatever he does. Be it monitoring the class, managing stuffs or in software field. He is always professional and determined.`,
    profileUrl: "https://www.linkedin.com/in/mala-kisku-7a16a9163/",
  },
  {
    name: "Abhinash Kumar Pandey",
    quote: `Pratap is wonderful to work with and has an outstanding Full Stack Development skills. We worked together in a project where he was very collaborative and he understands the requirements. He is open, helping, and very professional. It’s a pleasure and an honor to recommend Pratap to anyone who wants to hire him.`,
    profileUrl: "https://www.linkedin.com/in/abhinash-kumar-pandey-399080116/",
  },
  {
    name: "Abhijeet Kumar",
    quote: `I’ve worked closely with Pratap for the past months and I’m pleased to confirm his capabilities as a programmer. He is smart, creative and eager to develop his knowledge. Also, he's always glad to assist his colleagues when they're in need. Always open minded, he's interested in handling new challenges and extend his competence areas. I can definitely say that he has great communication skills - combined with a high sense of responsibility and professionalism. He's a responsible person and is always a pleasure to work with him. I’d recommend Pratap based on his experience, knowledge and personal skills.`,
    profileUrl: "https://www.linkedin.com/in/abhijeet4som1/",
  },
  {
    name: "Rajiv Singh",
    quote: `Pratap Sharma is one of the most effective among all people I've ever worked with. He is very thorough in everything he does and can be depended upon to get the job done. I would recommend him with any project that requires the very best in Developer execution. He is ready to deal with difficult situations and solve the problems on time. He always takes care of the job. I could always depend on him to set the best example as Full Stack Developer at Stonegrid Technology. I certainly would recommend him as Full Stack Developer.`,
    profileUrl: "https://www.linkedin.com/in/rajiv-singh-20b14361/",
  },
  {
    name: "Shweta Kumari",
    quote: `After knowing him for a long time. You can tell how much of a hard working person he is and also very good at keeping up with the industry standards for better quality products and very knowledgeable about the field.`,
    profileUrl: "https://www.linkedin.com/in/shweta-kumari-0ba65472/",
  },
];
